<template>
  <div class="fill-height container--fluid" style="width: 100%">
    <div class="container--fluid">
      <v-toolbar tile flat style="max-height: 30px">
        <v-btn icon v-on:click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Go back</v-toolbar-title>
        <v-spacer />

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              plain
              v-bind="attrs"
              v-on="on"
              icon
              @click="openDialog"
            >
              <v-icon size="24px" color="primary">mdi-bookmark-check</v-icon>
            </v-btn>
          </template>
          <span>I'm interested</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              plain
              v-bind="attrs"
              v-on="on"
              icon
              @click="sendPreference('like')"
            >
              <v-icon size="24px" color="red">mdi-heart</v-icon>
            </v-btn>
          </template>
          <span>I like</span>
        </v-tooltip>
      </v-toolbar>
    </div>
    <v-container fluid class="fill-height">
      <v-row align="start" justify="start">
        <v-col cols="12" md="6">
          <v-container fluid class="mx-0">
            <v-row no-gutters>
              <v-col cols="12">
                <span
                  class="text-h6 text-md-h5 font-weight-ligh headline text-uppercase"
                >
                  {{ profile.name }}
                </span>
              </v-col>
              <!-- <v-col cols="12">
                <div class="boxdivider"></div>
              </v-col> -->

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    large
                    @click="openReservationForm()"
                    style="width: 100%"
                    color="primary"
                  >
                    <!-- <v-icon color="green" size="44px">mdi-plus</v-icon> -->

                    <v-img
                      src="@/assets/images/cart.svg"
                      alt="cart"
                      style="
                        object-fit: contain;
                        max-width: 5%;
                        margin-right: 5%;
                        filter: brightness(0) invert(1);
                      "
                    >
                    </v-img>

                    <h3 class="text-uppercase" style="color: white">
                      add to itinerary
                    </h3>
                  </v-btn>
                </template>
                <span>Add to itinerary</span>
              </v-tooltip>

              <v-col cols="12">
                <v-carousel hide-delimiters cycle height="400" class="mt-5">
                  <v-carousel-item
                    v-for="item in profile.pictures"
                    :key="item.id"
                  >
                    <v-img
                      :src="item.url"
                      :lazy-src="require('@/assets/images/no-image.jpg')"
                      height="400"
                      max-height="400"
                      class="rounded-lg white--text justify-center elevation-1"
                    ></v-img>
                  </v-carousel-item>
                </v-carousel>

                <a
                  :href="profile.coepris_qr_link"
                  target="_blank"
                  style="display: block; text-align: center"
                >
                  <img
                    v-if="
                      profile.coepris_qr &&
                      isFechaVencida(profile.vencimiento_fecha)
                    "
                    src="@/assets/images/bcycoepris.png"
                    alt=""
                    style="width: 100%; background-color: #6a1b31"
                  />
                </a>
              </v-col>

              <v-col cols="12">
                <v-container fluid class="pa-0 mt-5">
                  <div :id="containerID" :style="containerCSS"></div>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
          <!--<v-row justify="start" align="start">
                        <v-card
                                class="d-flex fill-height fluid mt-5"
                                width="100%"
                                flat
                                elevation="0"
                                tile>
                            <v-img
                                    src="@/assets/images/mapa-location.png"
                                    transition="slide-x-transition"
                                    position="top"
                                    width="100%"
                                    height="400"
                                    max-height="400">
                                <v-toolbar class="ma-5" dense floating>
                                    <v-text-field
                                            hide-details
                                            prepend-icon="mdi-magnify"
                                            single-line>
                                    </v-text-field>
                                    <v-btn icon>
                                        <v-icon>mdi-crosshairs-gps</v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </v-img>
                        </v-card>
                    </v-row>-->
        </v-col>
        <v-col cols="12" md="6">
          <v-card elevation="0" class="ml-1">
            <v-container fluid class="mx-0">
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-button font-weight-medium"
                    >Description:
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-format-quote-close</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        {{
                          profile.description_english
                            ? profile.description_english
                            : profile.description
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">Address: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-hospital-marker</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        {{ profile.address }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">
                    Sanitary license:
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-certificate</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        {{ profile.license }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">Phones: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-phone</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        <!--{{ profile.phone1 | formatPhone('MX') }}, {{ profile.phone2 || "NO" | formatPhone('US') }}-->
                        {{ profile.phone1 | formatPhone("MX") }},
                        {{ profile.phone2 | formatPhone("US") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <span class="text-button font-weight-medium">E-mail: </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-email</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        {{ profile.email }}
                      </span>
                    </v-col>
                  </v-row>
                  <span class="text-button font-weight-medium"
                    >Attention schedule:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-icon color="primary">mdi-clock</v-icon>
                    </v-col>
                    <v-col>
                      <span
                        class="text-body-2 font-weight-ligh grey--text text--darken-1"
                      >
                        {{ profile.servicehours }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="6">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                    v-if="
                      profile.coepris_qr &&
                      isFechaVencida(profile.vencimiento_fecha)
                    "
                  >
                    <span class="text-button">
                      <small>
                        Scan or click to see the COEPRIS certification
                      </small>
                    </span>
                    <v-divider></v-divider>

                    <a
                      :href="profile.coepris_qr_link"
                      target="_blank"
                      style="display: flex"
                    >
                      <v-img
                        :src="profile.coepris_qr"
                        alt="qr"
                        style="width: 70%"
                      />
                    </a>
                  </div>
                </v-col>

                <v-col cols="12">
                  <span class="text-button font-weight-medium"
                    >Social media:</span
                  >
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            :href="profile.facebookprofile"
                            target="_blank"
                          >
                            <v-icon size="24px" color="primary"
                              >mdi-facebook</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Facebook</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            :href="profile.instagramprofile"
                            target="_blank"
                          >
                            <v-icon size="24px" color="primary"
                              >mdi-instagram</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Instagram</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            :href="profile.youtubeprofile"
                            target="_blank"
                          >
                            <v-icon size="24px" color="primary"
                              >mdi-youtube</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Youtube</span>
                      </v-tooltip>
                    </v-col>
                    <v-col>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            :href="profile.website"
                            target="_blank"
                          >
                            <v-icon size="24px" color="primary">mdi-web</v-icon>
                          </v-btn>
                        </template>
                        <span>Website</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <div
                    class="fill-height container--fluid justify-start mt-5"
                    v-if="profile.youtubeprofile != null"
                  >
                    <iframe
                      width="100%"
                      :height="$vuetify.breakpoint.xs ? 300 : 400"
                      :src="youtubeVideo"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="fill-height text-center mx-0">
      <v-row>
        <v-col cols="12">
          <v-sheet class="mx-0" max-width="100%">
            <v-img
              src="@/assets/images/pexels-photo-127873.png"
              class="grey lighten-2"
              width="100%"
              height="470"
            >
              <v-container fill-height bottom-gradient fluid class="ma-0">
                <v-row justify="center" align="center" class="mt-3 text-center">
                  <v-col>
                    <span
                      class="text-h6 text-md-h4 text-uppercase white--text font-weight-bold"
                      >Our Hospital Catalog</span
                    >
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-0">
                  <v-col cols="12">
                    <div class="fill-height">
                      <v-slide-group
                        center-active
                        v-model="model"
                        class="pa-0"
                        active-class="blue lighten-4"
                        next-icon="mdi-arrow-right-drop-circle-outline"
                        prev-icon="mdi-arrow-left-drop-circle-outline"
                        show-arrows
                      >
                        <v-container fluid class="container--fluid">
                          <v-row justify="center">
                            <v-slide-item
                              v-for="card in hospitals"
                              :key="card.id"
                              v-slot="{ active, toggle }"
                            >
                              <v-card
                                v-if="card.pictures[0] != null"
                                :color="active ? undefined : 'white'"
                                class="ma-1 rounded-lg"
                                height="auto"
                                :width="$vuetify.breakpoint.mobile ? 300 : 300"
                                elevation="10"
                                @click="toggle"
                              >
                                <div v-on:click="getProfile(card.id)">
                                  <v-img
                                    v-if="card.pictures[0] != null"
                                    :src="card.pictures[0].url"
                                    :lazy-src="
                                      require('@/assets/images/no-image.jpg')
                                    "
                                    class="white--text align-end justify-start rounded-t-lg"
                                    position="center"
                                    height="200px"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                  >
                                  </v-img>
                                  <div class="row">
                                    <div class="col-12">
                                      <v-card-subtitle
                                        class="pb-0 text-subtitle-1 indigo--text text-capitalize text-truncate"
                                      >
                                        {{ card.name }}
                                      </v-card-subtitle>
                                    </div>
                                  </div>
                                  <v-card-actions class="mt-1 justify-center">
                                    <v-btn
                                      color="blue"
                                      icon
                                      :href="card.facebook_profile"
                                      target="_blank"
                                    >
                                      <v-icon size="24px" color="primary"
                                        >mdi-facebook</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      color="blue"
                                      icon
                                      :href="card.instagram_profile"
                                      target="_blank"
                                    >
                                      <v-icon size="24px" color="primary"
                                        >mdi-instagram</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      color="blue"
                                      icon
                                      :href="card.youtubeprofile"
                                      target="_blank"
                                    >
                                      <v-icon size="24px" color="primary"
                                        >mdi-youtube</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      color="blue"
                                      icon
                                      :href="card.web_page"
                                      target="_blank"
                                    >
                                      <v-icon size="24px" color="primary"
                                        >mdi-web</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      v-show="false"
                                      color="blue"
                                      icon
                                      target="_blank"
                                    >
                                      <v-icon size="24px" color="primary"
                                        >mdi-twitter</v-icon
                                      >
                                    </v-btn>
                                  </v-card-actions>
                                </div>
                              </v-card>
                            </v-slide-item>
                          </v-row>
                        </v-container>
                      </v-slide-group>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-sheet>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="350">
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="row--dense mx-1 mt-2">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Email"
                required
              >
              </v-text-field>
            </div>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
              <v-btn
                :disabled="!valid"
                color="primary"
                text
                @click="acceptDialog"
              >
                Accept
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-container>
    <footer-profile-component />

    <v-dialog v-model="reservationFormDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Reservation times</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="reservation.date"
                  label="Reservation date"
                  type="date"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Campo de hora -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="reservation.hour"
                  label="Reservation time"
                  type="time"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeReservationForm">Cancel</v-btn>
          <v-btn color="success" @click="addToCart(profile)"
            >Add appointment</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackBar" :timeout="2000">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="snackBar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import hospitalsService from "@/providers/HospitalsService";
import FooterProfileComponent from "@/layouts/app-layout/footer/FooterProfileComponent";
import preferencesService from "@/providers/PreferencesService";

export default {
  name: "HospitalProfileComponent",
  components: { FooterProfileComponent },
  props: {
    hospital_id: Number,
    initialLocation: {
      type: Array,
      default: () => [-116.97480855890304, 32.547976944620714],
    },
    height: {
      type: String,
      default: "400px",
    },
    mapStyle: {
      type: String,
      // default: "mapbox://styles/mapbox/outdoors-v11",
      default: "mapbox://styles/mapbox/streets-v11",
    },
    color: {
      type: String,
      default: "orange",
    },
    containerID: {
      type: String,
      default: "map",
    },
  },
  data: () => ({
    selection: -1,
    model: null,
    youtubeVideo: "",
    snackBar: false,
    snackText: "",
    mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,
    valid: true,
    dialog: false,
    email: null,
    location: [],
    hospitals: [],
    profile: {
      id: 0,
      name: null,
      description: null,
      description_english: null,
      address: null,
      map_lng: null,
      map_lat: null,
      license: null,
      phone1: null,
      phone2: null,
      email: null,
      website: null,
      facebookprofile: null,
      instagramprofile: null,
      youtubeprofile: null,
      servicehours: null,
      pictures: [],
      specialties: [],
      certifications: [],
      doctors: [],
      extraservices: [],
      coepris_qr: "",
      vencimiento_fecha: "",
      coepris_qr_link: "",
    },
    emailRules: [
      (v) => !!v || "El Correo Electrónico es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
    ],

    reservationFormDialog: false,
    reservation: {
      category: "hospitales",
      id: "",
      date: "",
      hour: "",
    },
  }),
  computed: {
    containerCSS() {
      return {
        width: "100%",
        height: this.height,
      };
    },
  },
  filters: {
    formatPhone(phn, country) {
      // console.log('phn:', phn);
      if (phn == "undefined" || phn == null || phn === "") {
        return country;
      }
      switch (country) {
        case "US":
          return (
            "USA +1 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        case "MX":
          return (
            "MEX +52 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        default:
          return country;
      }
    },
  },
  methods: {
    openReservationForm() {
      this.reservationFormDialog = true;
    },

    closeReservationForm() {
      this.reservationFormDialog = false;
    },

    isFechaVencida(fecha) {
      const fechaVencimiento = fecha.split("T")[0]; // Obtiene solo la parte de la fecha
      const fechaActual = new Date().toISOString().split("T")[0]; // Formatea la fecha actual en el mismo formato

      // Compara las fechas
      return fechaVencimiento > fechaActual;
    },

    //cart

    addToCart(profile) {
      // Validar que la fecha y la hora estén seleccionadas
      if (!this.reservation.date || !this.reservation.hour) {
        this.snackText = "Select a date and time for the reservation.";
        this.snackBar = true;
      } else {
        this.reservationFormDialog = false;

        this.reservation.id = profile.id;

        const hospital = {
          category: "Hospital",
          name: profile.name,
          image: profile.pictures[0]?.url,
          profile: profile,
          reservation: this.reservation,
        };

        //   // Llama a la acción addToCart del store para agregar el doctor al carrito

        this.$store.dispatch("addToCart", hospital);

        this.snackText = "Reservation successfully added to itinerary.";
        this.snackBar = true;

        //   console.log(`agregado doctor ${doctor.profile.name}`);
      }
    },

    //////////////////////////////////////////

    goBack() {
      this.$router.push("/hospitals");
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    getHospitals() {
      hospitalsService.getViewList().then((record) => {
        this.hospitals = record.value;
        // console.log("Hospitales: ", this.hospitals);
      });
    },
    loadURL() {
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const url = this.profile.youtubeprofile.split(
        /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
      );
      // console.log("url", url);
      const YId =
        undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      // console.log("YId", YId);
      if (YId === url[0]) {
        console.log("not a youtube link");
      } else {
        console.log("it's  a youtube video");
      }
      const topOfQueue = youtubeEmbedTemplate.concat(YId);
      // console.log("topOfQueue", topOfQueue);
      this.youtubeVideo = topOfQueue;
    },
    getProfile(idHospital) {
      let record = null;
      hospitalsService.getRecord(idHospital).then((fetch_data) => {
        record = fetch_data.value;
        // console.log("data:", record);
        this.profile.id = record.id;
        this.profile.name = record.name;
        this.profile.description = record.description;
        this.profile.description_english = record.description_english;
        this.profile.address = record.address;
        this.profile.map_lng = record.map_lng;
        this.profile.map_lat = record.map_lat;
        this.profile.license = record.sanitary_license;
        this.profile.phone1 = record.phone1;
        this.profile.phone2 = record.phone2;
        this.profile.email = record.email;
        this.profile.website = record.web_page;
        this.profile.facebookprofile = record.facebook_profile;
        this.profile.instagramprofile = record.instagram_profile;
        this.profile.youtubeprofile = record.youtube_video;
        this.profile.servicehours = record.servicehours;
        this.profile.pictures = record.pictures;
        this.profile.doctors = record.doctors;
        this.profile.specialties = record.specialties;
        this.profile.certifications = record.certifications;
        this.profile.coepris_qr = record.coepris_qr;
        this.profile.vencimiento_fecha = record.vencimiento_fecha;
        this.profile.coepris_qr_link = record.coepris_qr_link;

        // console.log("profile:", this.profile);

        // Gestion de la ubicacion en el mapa.
        const lng = this.profile.map_lng;
        const lat = this.profile.map_lat;
        // console.log('lng: ', lng);
        // console.log('lat: ', lat);
        this.setLocationCoordinates({ lng, lat });
        this.createMap();

        if (this.profile.youtubeprofile != null) {
          this.loadURL();
        }
        this.toTop();
      });
    },
    async createMap() {
      try {
        mapboxgl.accessToken = this.mapBoxApiKey;
        // Create map object
        this.map = new mapboxgl.Map({
          container: this.containerID,
          style: this.mapStyle,
          center: this.location,
          zoom: 10,
        });
        // Controles basicos de navegacion: zoom y rotacion.
        this.map.addControl(new mapboxgl.NavigationControl());

        // Controles para el modo FullScreen
        this.map.addControl(new mapboxgl.FullscreenControl());

        // Evento del mapa.
        this.map.on("load", () => {
          // console.log('here');
          this.marker = new mapboxgl.Marker()
            .setLngLat(this.location)
            .addTo(this.map);
          // console.log(this.marker);
        });

        // Add Click Listener
        this.map.on("click", (event) => {
          console.log("evento: ", event);
          alert("Visitar el sitio oficial de MapBox");
          // window.location.href = 'https://www.mapbox.com/';
        });
      } catch (err) {
        console.error("map error", err);
      }
    },
    setLocationCoordinates(lngLat) {
      this.location = [lngLat.lng, lngLat.lat];
    },
    sendPreference(tag_type) {
      let tagView = tag_type === "mark" ? "Me interesa" : "Me Gusta";
      preferencesService.addRecord(this.email, tag_type, 2).then((result) => {
        if (result.success) {
          this.snackText =
            tag_type === "mark"
              ? `Se ha enviado los datos de esta vista etiquedada con ${tagView}! a su buzón de correo!`
              : `La vista ha sido etiquetada con ${tagView}!`;
          // Enviar los datos por correo electronico.
          hospitalsService
            .sendEmail(this.profile.id, this.email)
            .then((data) => {
              if (data.success) {
                console.log("Correo enviado: ", data);
              } else {
                console.log("Error enviando correo: ", data);
              }
            });
        } else {
          this.snackText = "Un error impidió etiquetar la vista!";
        }
        this.snackBar = true;
      });
    },
    openDialog() {
      this.email = "";
      this.dialog = true;
      //
    },
    closeDialog() {
      this.email = "";
      this.dialog = false;
    },
    acceptDialog() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.sendPreference("mark");
        this.dialog = false;
      }
    },
  },
  mounted() {
    // this.hospital_id = this.$route.params.id;
    this.getHospitals();
    this.getProfile(this.$route.params.id);
  },
};
</script>

<style scoped>
.boxdivider {
  background-color: transparent;
  width: 100%;
  height: 15px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
.bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(114, 217, 235, 0.185) 0%,
    rgba(101, 99, 248, 0.911) 100%
  );
}
.my-slider > .v-slide-group__next.theme--light.v-icon {
  color: rgb(234, 10, 10) !important;
  font-size: 24pt !important;
}
</style>
