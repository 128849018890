<template>
  <div>
    <hospital-profile-component />
  </div>
</template>

<script>
import HospitalProfileComponent from "@/components/hospitals-clinics/HospitalProfileComponent";

export default {
  name: "HospitalProfile",
  title: "Hospital Profile | Baja California Health Tourism",
  components: {
    HospitalProfileComponent,
  },
};
</script>

<style></style>
